import api from 'src/enums/api';
import httpService from 'src/utils/httpService';

export const GET_BOOKS = '@cnbot/get-books';

export function getBooks() {
  return async dispatch => {
    const response = await httpService.makeRequest('get', api.books);

    dispatch({
      type: GET_BOOKS,
      payload: response
    });
  };
}
