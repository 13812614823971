import { combineReducers } from 'redux';
import balancesReducer from './balancesReducer';
import jobReducer from './jobReducer';
import accountsReducer from './accountsReducer';
import machineReducer from './machineReducer';
import transactionReducer from './transactionReducer';
import reportReducer from './reportReducer';
import settingReducer from './settingReducer';
import transferReducer from './transferReducer';
import booksReducer from './booksReducer';

const rootReducer = combineReducers({
  accounts: accountsReducer, // guncellenecek
  balances: balancesReducer,
  jobs: jobReducer,
  machine: machineReducer,
  transaction: transactionReducer,
  report: reportReducer,
  setting: settingReducer,
  transfer: transferReducer,
  books: booksReducer
});

export default rootReducer;
