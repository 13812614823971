import React from 'react';
import {
  FormControl,
  FormHelperText,
  ListItemText,
  makeStyles,
  MenuItem,
  Select as MSelect,
  SvgIcon
} from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Circle as CircleIcon
} from 'react-feather';

const useStyles = makeStyles(() => ({
  selectItem: {
    marginRight: 10
  }
}));

function Select({
  id,
  name,
  items = [],
  value = null,
  onChange,
  onBlur,
  renderCell,
  error,
  selectedShow = false,
  disabled = false,
  className
}) {
  const classes = useStyles();

  const renderCellHandler = value => {
    if (renderCell) return renderCell(value);

    return <ListItemText primary={value} />;
  };
  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      className={className}
    >
      <MSelect
        id={id}
        value={value}
        disabled={disabled}
        displayEmpty
        renderValue={() => (value ? renderCellHandler(value) : name)}
        onChange={event => {
          event.target.name = id || name;
          onChange && onChange(event);
        }}
        onBlur={event => {
          event.target.name = id || name;
          onBlur && onBlur(event);
        }}
        error={Boolean(error)}
      >
        {items.map(v => (
          <MenuItem key={v} value={v}>
            {selectedShow && (
              <SvgIcon fontSize="small" className={classes.selectItem}>
                {value === v ? (
                  <CheckCircleIcon color="green" />
                ) : (
                  <CircleIcon />
                )}
              </SvgIcon>
            )}
            {renderCellHandler(v)}
          </MenuItem>
        ))}
      </MSelect>
      <FormHelperText error>{error}</FormHelperText>
    </FormControl>
  );
}

export default Select;
