import React, { useEffect, useState } from 'react';
import { Grid, Hidden, makeStyles, Tab, Tabs } from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTransactions } from 'src/store/actions/transactionActions';

import TransactionsTable from '../transactions/components/TransactionsTable';
import FailTransactionsTable from '../transactions/components/FailTransactionsTable';
import EarningByCoins from './Earnings';
import TotalBalance from 'src/layouts/DashboardLayout/TopBar/TotalBalance';
import OrderFailedTable from '../transactions/components/OrderFailedTable';
import InsufficientTransactionTable from '../transactions/components/InsufficientTransactionTable';
import Permission, { UserRoles } from 'src/components/Permission';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 64,
      paddingRight: 64
    }
  }
}));

const tabs = {
  completed: {
    value: 'completed',
    label: 'Successful Transactions'
  },
  canceled: {
    value: 'canceled',
    label: 'Canceled Transactions'
  },
  error: {
    value: 'error',
    label: 'Failed Transactions'
  },
  fail: {
    value: 'order-failed',
    label: 'Order Failed'
  },
  insufficient: {
    value: 'insufficient',
    label: 'Insufficient Transactions',
    roles: [UserRoles.DEVELOPER, UserRoles.MAINTAINER]
  }
};

function DashboardModule() {
  const classes = useStyles();
  const selectedRole = useSelector(state => state.setting.selectedRole);
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState(tabs.completed.value);

  useEffect(() => {
    getAllTransactions();
  }, [dispatch]);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const filterTabsByRole = () => {
    const filteredTabs = [];
    Object.values(tabs).forEach(tab => {
      if (!tab.roles || (tab.roles && tab.roles.includes(selectedRole))) {
        filteredTabs.push(tab);
      }
    });
    return filteredTabs;
  };

  return (
    <Page
      className={classes.root}
      title=""
      rightContainer={
        <Hidden mdUp>
          <TotalBalance isDashboard />
        </Hidden>
      }
    >
      <Grid container spacing={3}>
        <Grid item lg={4} xl={4} xs={12}>
          <EarningByCoins />
        </Grid>
        <Grid item lg={8} xl={8} xs={12}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            variant="scrollable"
            textColor="secondary"
            value={currentTab}
          >
            {filterTabsByRole().map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          {currentTab === tabs.completed.value && <TransactionsTable />}
          {currentTab === tabs.canceled.value && (
            <TransactionsTable options={{ status: 'canceled' }} />
          )}
          {currentTab === tabs.error.value && (
            <FailTransactionsTable options={{ status: 'error' }} />
          )}
          {currentTab === tabs.fail.value && <OrderFailedTable />}
          <Permission roles={[UserRoles.DEVELOPER, UserRoles.MAINTAINER]}>
            {currentTab === tabs.insufficient.value && (
              <InsufficientTransactionTable />
            )}
          </Permission>
        </Grid>
      </Grid>
    </Page>
  );
}

export default DashboardModule;
