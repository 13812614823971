import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  header: {
    marginBottom: theme.spacing(3),
    paddingRight: theme.spacing(2)
  },
  ticker: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    width: 'unset'
  },
  content: {},
  coinText: {
    marginRight: theme.spacing(2)
  },
  coinSelect: {
    width: 200,
    marginRight: theme.spacing(2),
    '& span': {
      fontSize: theme.typography.h1.fontSize
    }
  },
  divider: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3)
  },
  currencyContainer: {
    maxHeight: 800
  },
  currencyHeader: {
    margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px 0`,
    textTransform: 'capitalize'
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));
