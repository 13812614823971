import React, { useEffect, useState } from 'react';
import { Divider, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { useStyles } from './Books.styles';
import Page from 'src/components/Page';
import { useDispatch, useSelector } from 'react-redux';
import Select from '../../components/Select';
import { useFormik } from 'formik';
import DataTable from '../../components/DataTable';
import { formatDate } from '../../utils/format';
import { getBooks } from '../../store/actions/booksActions';

function BooksPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBooks());

    const interval = setInterval(() => {
      dispatch(getBooks());
    }, 2000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const [selectedTab, setSelectedTab] = useState('binance');

  const formik = useFormik({
    initialValues: { selectedCoin: 'ETH' }
  });

  const coins = useSelector(state => state.books.coins);
  const counts = useSelector(state => ({
    total: state.books.response.booksCount || '-',
    binance: state.books.response.exchange?.binance || '-',
    paribu: state.books.response.exchange?.paribu || '-'
  }));
  const selectedCoinBook = useSelector(state => {
    const selectedBook = state.books.booksByCoin[formik.values.selectedCoin];
    const usdtBooks = selectedBook?.payloads[selectedTab]?.USDT || {
      asks: [],
      bids: []
    };
    const tryBooks = selectedBook?.payloads[selectedTab]?.TRY || {
      asks: [],
      bids: []
    };

    return {
      exchanges: selectedBook?.exchanges || [],
      usdt: {
        asks: usdtBooks.asks.map(([price, volume], index) => ({
          price,
          volume,
          index
        })),
        bids: usdtBooks.bids.map(([price, volume], index) => ({
          price,
          volume,
          index
        })),
        date: usdtBooks.date
      },
      try: {
        asks: tryBooks.asks.map(([price, volume], index) => ({
          price,
          volume,
          index
        })),
        bids: tryBooks.bids.map(([price, volume], index) => ({
          price,
          volume,
          index
        })),
        date: tryBooks.date
      }
    };
  });
  const ticker = useSelector(state => state.books.tickers[selectedTab]);

  const bookColumns = [
    {
      key: 'price',
      title: 'Price'
    },
    {
      key: 'volume',
      title: 'Volume'
    }
  ];

  return (
    <Page className={classes.root}>
      <Grid
        className={classes.header}
        container
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item container alignItems="center" spacing={2}>
          <Typography
            variant="h2"
            color="textPrimary"
            className={classes.coinText}
          >
            Book in view
          </Typography>
          <Select
            id="selectedCoin"
            className={classes.coinSelect}
            name="coin"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.selectedCoin}
            items={coins}
          />
        </Grid>
        <Grid item container alignItems="center" justify="flex-end" spacing={2}>
          <Grid item>
            <Typography color="textSecondary">
              Binance{' '}
              <Typography variant="h3" color="textPrimary">
                {counts.binance}
              </Typography>
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="textSecondary">
              Paribu{' '}
              <Typography variant="h3" color="textPrimary">
                {counts.paribu}
              </Typography>
            </Typography>
          </Grid>
          <Divider
            className={classes.divider}
            orientation="vertical"
            color="textPrimary"
            flexItem
          />
          <Grid item>
            <Typography color="textSecondary">
              Total{' '}
              <Typography variant="h3" color="textPrimary">
                {counts.total}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.content} container direction="column">
        <Grid item style={{ position: 'relative' }}>
          <Tabs
            textColor="secondary"
            variant="scrollable"
            onChange={(event, value) => setSelectedTab(value)}
            value={selectedTab}
          >
            {selectedCoinBook?.exchanges.map(exchange => (
              <Tab key={exchange} label={exchange} value={exchange} />
            ))}
          </Tabs>
          {ticker && (
            <Grid container className={classes.ticker}>
              <DataTable
                columns={[
                  {
                    key: 'usdt',
                    title: 'usdt',
                    width: 10
                  },
                  { key: 'try', title: 'try', width: 10 }
                ]}
                source={[{ usdt: ticker.buy, try: ticker.sell }]}
              />
            </Grid>
          )}
        </Grid>
        {selectedCoinBook && (
          <Grid item container xs={12} spacing={2} justify="space-between">
            {['usdt', 'try'].map(currency => (
              <Grid container item direction="column" xs={6}>
                <Typography
                  variant="h3"
                  color="textPrimary"
                  className={classes.currencyHeader}
                >
                  {currency}
                </Typography>
                <Grid
                  container
                  item
                  className={classes.currencyContainer}
                  spacing={1}
                >
                  {['asks', 'bids'].map(type => (
                    <Grid item xs={6}>
                      <DataTable
                        head={
                          <div className={classes.tableHeader}>
                            <span>{type.toUpperCase()}</span>
                            <span>
                              {selectedCoinBook[currency].date
                                ? formatDate(selectedCoinBook[currency].date)
                                : ''}
                            </span>
                          </div>
                        }
                        columns={[
                          {
                            key: 'index',
                            title: '',
                            renderCell: value => `${value + 1}. `,
                            width: 10
                          },
                          ...bookColumns
                        ]}
                        source={selectedCoinBook[currency][type]}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Page>
  );
}

export default BooksPage;
