import produce from 'immer';
import { GET_BOOKS } from '../actions/booksActions';

const initialState = {
  response: {},
  booksByCoin: {},
  coins: [],
  tickers: {}
};

const booksReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKS: {
      return produce(state, draft => {
        draft.response = action.payload;
        draft.booksByCoin = action.payload?.books.reduce((acc, book) => {
          const reducedBook = book.reduce(
            (acc, curr) => {
              const [coin, currency] = curr.parity.split('/');
              const exchange = curr.exchange;

              if (!acc.exchanges.includes(exchange)) {
                acc.exchanges.push(exchange);
                acc.exchanges.sort();
              }
              acc.coin = coin;
              acc.payloads[exchange] = {
                ...acc.payloads[exchange],
                [currency]: { ...curr.payload, date: new Date(curr.date) }
              };

              return acc;
            },
            {
              exchanges: [],
              coin: undefined,
              payloads: {},
              date: undefined
            }
          );

          acc[reducedBook.coin] = reducedBook;
          delete reducedBook.coin;

          return acc;
        }, {});

        draft.coins = Object.keys(draft.booksByCoin).sort();
        draft.tickers = Object.keys(action.payload.tickers).reduce(
          (acc, exchange) => {
            const ticker = action.payload.tickers[exchange];
            acc[exchange] = {
              date: new Date(ticker.date),
              buy: ticker.buy,
              sell: ticker.sell
            };

            return acc;
          },
          {}
        );
      });
    }

    default: {
      return state;
    }
  }
};

export default booksReducer;
