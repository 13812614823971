import axios from 'axios';

class HttpUtil {
  constructor() {
    this.axios = axios.create();
  }

  async makeRequest(method, url, data = {}, config = {}) {
    try {
      const response = await this.axios[method](url, data, { ...config });
      return response.data?.data;
    } catch (error) {
      // console.log(error.response.data?.data);
      console.log(error);
      throw new Error('API: Occurred an error!');
    }
  }

  getInstance() {
    return this.axios;
  }
}

const httpService = new HttpUtil();

export default httpService;
