export function formatPrice(price, decimals = 2) {
  return price && price !== 0
    ? new Intl.NumberFormat().format(price.toFixed(decimals))
    : '0.00';
}

export function formatDate(date) {
  const pad = num => String(num).padStart(2, '0'); // Ensure two digits
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1); // Months are 0-indexed
  const year = date.getFullYear();
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
}
